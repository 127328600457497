import { useContext, useEffect, useState } from "react";
import {
  HiOutlineArrowSmLeft,
  HiOutlineArrowSmRight,
  HiOutlineUpload,
} from "react-icons/hi";

import { Button } from "../../../UI";
import { GeneralContext } from "../../../contexts/general.context";
import classes from "./DestinationInfo.module.css";
import { EnglishProficiencyContext } from "../../../contexts/englishProficiency";
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";

export const DocumentSelect = ({ handleNext, handleBack }) => {
  const [documentNames, setDocmentNames] = useState({});
  const [uploadingFile, setUploadingFile] = useState();
  const { uploadFile } = useContext(GeneralContext);
  const { englishProficiencyInfo, setEnglishProficiencyInfo } = useContext(
    EnglishProficiencyContext
  );
  //initialize the document object in the transcript info object
  useEffect(() => {
    setEnglishProficiencyInfo((prev) => ({
      ...prev,
      document: {
        previousTranscriptRecieptsUrl: "",
        clearanceORpaymentRecieptsUrl: "",
        attachingDocumentUrls: [""],
      },
    }));
    //eslint-disable-next-line
  }, []);

  const handleNextBtn = (e) => {
    e.preventDefault();
    if (documentNames?.officialDoc) {
      englishProficiencyInfo.document.documentType = 1;
      handleNext();
    }
  };

  const onFileChange = async (e) => {
    setUploadingFile(true);
    const { files, id, name } = e.target;
    const { data: documentUrl } = await uploadFile(files[0]);
    if (!documentUrl) return;

    if (documentUrl) {
      setUploadingFile(false);
    } else {
      toast.error("Please upload document again...");
      setUploadingFile(false);
    }

    if (id === "attachingDoc") {
      englishProficiencyInfo.document[name] = [documentUrl];
      setDocmentNames((prev) => ({ ...prev, [id]: [files[0].name] }));
      return;
    }

    englishProficiencyInfo.document[name] = documentUrl;
    setDocmentNames((prev) => ({ ...prev, [id]: files[0].name }));
  };

  return (
    <form className={classes.documentSelect} onSubmit={handleNextBtn}>
      <h1>Upload Documents</h1>
      <div className={classes.documentSelect__documentContainer}>
        <h4>
          Official Document<span>*</span>
        </h4>
        <div className={classes.documentSelect__inputContainer}>
          <div className={classes.documentSelect__input}>
            {/* <Select /> */}

            <p>
              Provide Statement of result/ Clearance form/ Payment receipts
              (provide all receipts in a single pdf):
            </p>
            <label htmlFor="officialDoc">
              {uploadingFile ? (
                <ImSpinner3 className="animate-spin" size={22} />
              ) : (
                <>
                  <HiOutlineUpload /> Upload file
                </>
              )}
            </label>
            <input
              type="file"
              id="officialDoc"
              onChange={onFileChange}
              name="clearanceORpaymentRecieptsUrl"
              required
            />
            <p>{documentNames?.officialDoc}</p>
          </div>
        </div>
      </div>

      <div className="flex gap-8 justify-center">
        <Button onClick={handleBack} variant="invert" className="flex gap-2">
          <HiOutlineArrowSmLeft size={20} />
          Back
        </Button>
        <Button type="submit" className="flex gap-2">
          Next <HiOutlineArrowSmRight size={20} />
        </Button>
      </div>
    </form>
  );
};
